import { createSelector } from 'reselect';

import { isB2B } from '@/domains/core/settings/utils';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import type { AdvertiserInformation } from '@/domains/productDiscovery/CommercialAnimation/interfaces/AdvertiserInformation';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';

import type { SpartacuxProductDiscoveryDomainState } from '../productDiscovery.model';

export const marketingCampaignsSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): BannerCampaign[] | undefined =>
  state.productDiscovery?.commercialAnimation?.marketingCampaigns?.items;

export const isFetchingMarketingCampaignsSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean =>
  state.productDiscovery?.commercialAnimation?.marketingCampaigns?.fetching ||
  false;

export const marketingCampaignsErrorSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): string | undefined =>
  state.productDiscovery?.commercialAnimation?.marketingCampaigns?.error;

export const advertiserInformationSelector = (
  state: SpartacuxProductDiscoveryDomainState,
): AdvertiserInformation | undefined =>
  state.productDiscovery?.commercialAnimation?.advertiserInformation?.data;

export const userSegmentsSelector = createSelector([authSelector], (auth) => {
  const IS_B2B = isB2B();
  return {
    ...(auth.loyalty_level && { loyalty_level: auth.loyalty_level }),
    ...(auth.profile && { profile: auth.profile }),
    ...(auth.b2b_intention &&
      auth.b2b_intention !== 'UNKNOWN' && {
        b2b_intention: auth.b2b_intention,
      }),
    ...(auth.b2b_suspected_pro && {
      b2b_suspected_pro: auth.b2b_suspected_pro,
    }),
    ...(auth.audience_group && { audience_group: auth.audience_group }),
    ...(auth.cluster && !IS_B2B && { b2c_cluster: auth.cluster }),
    ...(auth.cluster && IS_B2B && { b2b_cluster: auth.cluster }),
    ...(auth.app_cluster && { app_cluster: auth.app_cluster }),
    ...(auth.last_chance_churn && {
      last_chance_churn: auth.last_chance_churn,
    }),
    ...(auth.anti_churn && { anti_churn: auth.anti_churn }),
    ...(auth.b2b_subscription &&
      IS_B2B && { b2b_subscription: auth.b2b_subscription }),
    ...(auth.customer_value && { customer_value: auth.customer_value }),
  };
});
